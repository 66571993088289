body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.ant-list-grid .ant-col > .ant-list-item {
  display: flex;
  margin-top: 16px;
}

.tournament-list-ant-card-body > .ant-card-body {
  margin: 10px;
  padding: 0;
}

.tournament-list-ant-card-body.ant-card {
  margin: 3px;
}

.ant-card-hoverable:hover .ant-list-item-meta {
  transition: opacity 0.3s;
  opacity: 1 !important;
}

div.ant-collapse-content {
  border-top: 0;
}

.scroll-section {
  height: calc(100vh - 54px - 96px);
  overflow: auto;
  padding: 0 16px;
  border: 1px solid rgba(140, 140, 140, 0.35);
  width: 100%;
}

.tablet-scroll-section {
  height: calc(100vh - 105px - 96px);
  overflow: auto;
  padding: 0 16px;
  border: 1px solid rgba(140, 140, 140, 0.35);
  width: 100%;
}

.mobile-scroll-section {
  height: calc(100vh - 54px - 49.14px - 44px - 16px - 16px);
  overflow: auto;
  padding: 0 16px;
  border: 1px solid rgba(140, 140, 140, 0.35);
  width: 100%;
}

.mobile-match-scroll-section {
  height: calc(100vh - 90px - 64px);
  overflow: auto;
  padding: 0 16px;
  border: 1px solid rgba(140, 140, 140, 0.35);
  width: 100%;
}

.confirmation-scroll-section {
  height: calc(100vh - 64px - 103.5px - 32px - 36px - 64px);
  height: calc(var(--vh, 1vh) * 100 - 64px - 103.5px - 32px - 36px - 64px);
  overflow: auto;
  padding: 0 16px;
  width: 100%;
}

.confirmation-warning-scroll-section {
  height: calc(100vh - 64px - 103.5px - 32px - 36px - 64px - 140px);
  height: calc(var(--vh, 1vh) * 100 - 64px - 103.5px - 32px - 36px - 64px - 140px);
  overflow: auto;
  padding: 0 16px;
  width: 100%;
}

.share-scroll-section {
  height: calc(100vh - 96px - 150px - 56.5px - 32px);
  overflow: auto;
  padding: 0 16px;
  border: 1px solid rgba(140, 140, 140, 0.35);
  width: 100%;
}

.mobile-share-scroll-section {
  height: calc(100vh - 64px - 96px - 46px - 32px);
  overflow: auto;
  padding: 0 16px;
  border: 1px solid rgba(140, 140, 140, 0.35);
  width: 100%;
}

.divider.ant-divider {
  margin: 0;
  border-top-color: rgba(140, 140, 140, 0.35);
}

.filter-border {
  border: 1px solid rgba(140, 140, 140, 0.35);
}

.ant-tabs-content-top {
  height: 100%;
}

.MuiDrawer-paperAnchorBottom {
  height: 100vh !important;
}

.PhoneInputInput {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}

ul.ant-list-item-action {
  margin-left: 8px;
}

.ant-list-item-action>li:first-child {
  padding: 0;
}

button.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  border-color: #f7941d;
  background: #f7941d;
}

button.ant-btn-dangerous.ant-btn-primary:focus,
button.ant-btn-dangerous.ant-btn-primary:hover {
  color: #fff;
  border-color: #faad1d;
  background: #faad1d;
}

button.ant-btn-primary.button-danger {
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
}

button.ant-btn-primary.button-danger:focus,
button.ant-btn-primary.button-danger:hover {
  color: #fff;
  border-color: #ff7875;
  background: #ff7875;
}

button.ant-btn-primary,
span.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #27aae1;
  background: #27aae1;
}

button.ant-btn-primary:focus,
button.ant-btn-primary:hover {
  border-color: #5ba5b0;
  background: #5ba5b0;
}

div.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #27aae1;
}

div.ant-tabs-ink-bar {
  background: #27aae1;
}

div.ant-tabs-tab {
  font-size: 16px;
}

.ant-list-item {
  flex-wrap: nowrap !important;
}

.empty-container.ant-empty-normal {
  margin: 0;
  padding-top: 32px;
  border: 1px solid rgba(140, 140, 140, 0.35);
}

.empty-container-matches.ant-empty-normal {
  margin: 0;
  padding-top: 32px;
  border: 1px solid rgba(140, 140, 140, 0.35);
}

div.ant-list-item-meta-avatar {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}